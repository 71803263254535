<template>
  <div :class="['col-5 h-50 mx-auto d-flex rounded', componentName ? '' : 'border border-dark']">
    <b-dropdown v-if="!componentName" class="m-auto">
      <template #button-content>
        <i class="fas fa-plus"/>
      </template>
      <b-dropdown-item @click="setComponent('list')">Active Incidents Listing</b-dropdown-item>
      <b-dropdown-item @click="setComponent('map')">Active Incidents Map</b-dropdown-item>
    </b-dropdown>
    <!-- TODO: Create the components to fit and be only used on this page as slot for dashboard -->
    <div v-if="componentName === 'incidentListing'" class="w-100">
      <IncidentsListing :incidents="getActiveIncidents" :updating="updating"/>
    </div>
    <div v-if="componentName === 'map'" class="w-100">
      <!-- <Map /> -->
    </div>
  </div>
</template>
<script>

import IncidentsListing from '@/components/incidents/incidents-listing';
import { incidentsComputed, incidentsMethods, authComputed } from '@/state/helpers';

export default {
  props: ['componentName'],
  data() {
    return {
      listing: null,
      updating: false,
    };
  },
  components: {
    IncidentsListing,
  },
  computed: {
    ...incidentsComputed,
    ...authComputed,
  },
  methods: {
    ...incidentsMethods,
    setComponent(type) {
      this.$emit('changeComponent', type);
    },
  },
  async mounted() {
    // this.updating = true;
    // await this.loadActiveIncidents({ user: JSON.parse(this.currentUser) });
    // this.updating = false;
  },
};
</script>
