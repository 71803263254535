<script>
import Layout from '@/router/layouts/main';
import appConfig from '@/app.config';
import DashboardSlots from '@/components/widgets/dashboard-slots';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: 'Dashboard',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    DashboardSlots,
    Layout,
  },
  data() {
    return {
      websocket: null,
      slots: [
        {
          component: null,
        },
        {
          component: null,
        },
        {
          component: null,
        },
        {
          component: null,
        },
      ],
    };
  },
  computed: {},
  methods: {
    changeComponent(event) {
      console.log(event);
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row row-cols-2" style="height: 82vh">
      <!-- TODO: Get from the backend and store on vuex the slots preferences of the user -->
      <dashboard-slots v-for="(slot, index) in slots" :key="index"
                       :component-name="slot.component" @changeComponent="changeComponent"/>
    </div>
  </Layout>
</template>
